import { Box, Modal, Typography } from "@mui/material";
import { styles } from "./modalStyles";
import React from "react";
import StandardButton from "./UI/Button";

interface BottomTextProps {
  handleClose: () => void;
  open: boolean;
}

const BottomText: React.FC<BottomTextProps> = ({ handleClose, open }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.modal}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Email Sent
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          We will get back to you as soon as we can
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end " }}>
          <StandardButton
            text="close"
            onClick={handleClose}
            style={{ width: "10em", marginTop: 2 }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default BottomText;
