import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Colors } from "../../constants/colors";

const CustomButton = styled(Button)(({ theme, customColor }: any) => ({
  backgroundColor: customColor,
  "&:disabled": {
    backgroundColor: theme.palette.grey[500], // set to the shade of grey you want
    color: theme.palette.grey[300], // set to the color you want for disabled state
  },
}));

interface StandardButtonProps {
  text: string;
  onClick?: any;
  style?: object;
  disabled?: boolean;
}

const StandardButton: React.FC<StandardButtonProps> = ({
  text,
  onClick,
  style,
  disabled,
}) => {
  return (
    <CustomButton
      // @ts-ignore
      customColor={Colors.PRIMARY_BLUE}
      sx={style}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </CustomButton>
  );
};

export default StandardButton;
