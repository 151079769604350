import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
//@ts-ignore
import NytesHeader from "./assets/header.svg";
import ContactForm from "./components/ContactForm";
import BottomText from "./components/BottomText";
import Modal from "./components/Modal";
import React from "react";

function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="sm">
        <ContactForm openModal={handleOpen} />
        <BottomText />
        <Modal handleClose={handleClose} open={open} />
      </Container>
    </>
  );
}

export default App;
