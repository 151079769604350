import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface BottomTextProps {}

const BottomText: React.FC<BottomTextProps> = ({}) => {
  return (
    <Typography
      sx={{
        marginTop: "2em",
        cursor: "pointer",
        userSelect: "none",
        textDecoration: "underline",
      }}
      textAlign={"center"}
    >
      <Link style={{ textDecoration: "none" }} to={"/privacy"}>
        Privacy Policy
      </Link>
    </Typography>
  );
};

export default BottomText;
