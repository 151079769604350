export const Colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  TEXT_INPUT_BACKGROUND: "#f5f4f4",
  BACKGROUND: "#F5F5F5",
  INPUT_BORDER_COLOR: "#dedede",
  INPUT_PLACEHOLDER_TEXT: "#838383",
  FADED_TEXT_COLOR: "#rgba(1, 29, 65, 0.5)",
  ITEM_BORDER_COLOR: "#d4d4d4",
  ALBUM_FEED_BACKGROUND: "#FFFFFF",
  ALBUM_FEED_CARD_BORDER: "#e1e0e0",
  VIEW_CHANGER_BACKGROUNDCOLOR: "#e9e9e9",
  COMMENT_BODY_BACKGROUND: "#e8f1fb",
  UNLIKED_HEART_COLOR: "#818181",
  LIKED_HEART_COLOR: "#EB5C5C",
  PRIMARY_BLUE: "#010143",
  PRIMARY_PURPLE: "#3E019A",
  LIGHT_PRIMARY_PURPLE: "#5421a0",
  LIGHTER_PRIMARY_PURPLE: "#7d54bb",
  LIGHTEST_PRIMARY_PURPLE: "#c1aedd",
  GREEN_ICON: "#58bd61",
  error100: "#ff7b7b",
  error500: "#ff0b03",
  grey100: "#d4d4d4",
  grey300: "#888888",
  grey500: "#565656",
};
