import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import StandardButton from "./UI/Button";
import { Box, TextField } from "@mui/material";
import { send } from "emailjs-com";
import { styles } from "./contactFormStyles";
//@ts-ignore
import NytesHeader from "../assets/cover-dark.svg";

interface ContactFormProps {
  openModal: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ openModal }) => {
  const [nameInput, setNameInput] = useState("");
  const [userEmailInput, setUserEmailInput] = useState("");
  const [messageText, setMessageText] = useState("");

  const [toSend, setToSend] = useState({
    name: "",
    message: "",
    userEmail: "",
  });

  const handleChange = (e: any) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    openModal();
    send(
      "service_x9fhpw3",
      "template_22j2oe7",
      //@ts-ignore
      {
        name: nameInput,
        message: messageText,
        userEmail: userEmailInput,
      },
      "mwP5NquBZaDGFG_vp"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setNameInput("");
        setUserEmailInput("");
        setMessageText("");
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <Card
      sx={{
        borderRadius: "1em",
        backgroundImage: `url(${NytesHeader})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        marginTop: "10%",
      }}
    >
      <CardContent sx={{ marginTop: "50%" }}>
        <form onSubmit={sendEmail}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            sx={{ marginBottom: "2em" }}
          />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            value={userEmailInput}
            onChange={(e) => setUserEmailInput(e.target.value)}
            sx={{ marginBottom: "2em" }}
          />
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Message"
            multiline
            maxRows={6}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        </form>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <StandardButton
          text="Send"
          onClick={sendEmail}
          style={{ width: "10em" }}
          disabled={!userEmailInput || !messageText}
        />
      </Box>
    </Card>
  );
};

export default ContactForm;
